// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin colors-theme($theme) {
    
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    
    .background-blue-and-grey,
    app-channel-list-item>a.active,
    app-channel-list-item>a.active::before,
    mat-drawer.sidenav-users ul li a .mat-badge-content,
    .mat-mdc-dialog-container .mat-mdc-dialog-title,
    .ql-mention-list-item.selected,
    nb-menu .menu-item a.active::before,
    .dx-popup-title.dx-toolbar {
        background-color: rgb(3, 155, 229) !important;        
    }
    .show-new-message-line {
        border-color: lavender !important;
        color: rgb(3, 155, 229);
    }
    
    .pace .pace-progress-inner {
        box-shadow: 0 0 10px nb-theme(color-primary-default), 0 0 5px nb-theme(color-primary-default);
    }
    .pace .pace-activity {
        display: none;
    }
    
    .mention {
        background: rgba(3, 155, 229, 0.2);        
    }
    .background-color,
    .dx-pivotgrid,
    .ql-container,
    .dx-datagrid-header-panel,
    .dx-toolbar-after,
    .dx-cell-focus-disabled,
    .dx-datagrid-drag-action,
    .dx-datagrid,
    .dx-datagrid-filter-row,
    .dx-datagrid-rowsview .dx-row,
    #tab-panel,
    #tab-toolbar,
    .ql-mention-list-container,
    .dx-overlay-content,
     ::-webkit-scrollbar-track {
        background: white;
    }
    
    .text-color,
    .ql-editor.ql-blank::before,
    .col-header p,
    .dx-datagrid-text-content,
    .dx-placeholder,
    .dx-icon-search:before,
    .dx-sort-down,
    .dx-datagrid .dx-row>td,
    .ql-mention-list-item,
    .dx-dialog-message,
    .dx-pivotgrid-area td {
        color: black;
    }
    .dx-popup-title.dx-toolbar {
        color: white;
    }
    .dx-editor-underlined:after {
        border-color: black;
    }
    .custom-box-shadow {
        box-shadow: -5px -1px 42px 21px rgba(0, 0, 0, 0.12) !important;
    }
    .custom-background-card {
        background: map-get($background, card);
    }
    .secondary-text {
        color: map-get($foreground, secondary-text);
    }
    .icon,
    i {
        color: map-get($foreground, icon);
    }
}