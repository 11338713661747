/* Global styles for Material Dialogs */
.mat-mdc-dialog-container {
  padding: 0 !important;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  max-height: 85vh !important;
  display: flex !important;
  flex-direction: column;
  position:relative;
}
.mat-mdc-dialog-container .mdc-dialog__container {
  width: 100%;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title {
    color: white !important;
    min-height: 64px;
    box-sizing: border-box;
    padding: 0 16px;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Muli', 'Helvetica Neue', 'Arial', sans-serif;
    font-size: 14px;
    
    .mat-icon-button {
      margin-left: auto;
      background-color: transparent;
      color: #ffffff;
      border: none;
    }
}
.mat-mdc-dialog-container .mat-mdc-dialog-title button .mat-icon {
  position: relative;  
}
.mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0 0 65px 0 !important;
  padding: 20px !important;
  box-sizing:border-box;
  max-height: calc(85vh - 130px);
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions {
    margin: 0px !important;
    min-height: 52px;
    padding: 16px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute !important;
    bottom: 0;
    right: 0;
    width: 100%;
}