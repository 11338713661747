@use '@angular/material' as mat;
@include mat.core();

// Import Conflow core library
@import "./app/shared/scss/core";


// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "./app/features/channel/channel.theme.scss";
// Define a mixin for easier access
@mixin components-theme($theme) {
    @include channel-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the light theme
// -----------------------------------------------------------------------------------------------------

$my-app-primary: mat.define-palette(mat.$blue-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, 500, 900, A100);
$my-app-warn: mat.define-palette(mat.$deep-orange-palette);
$diva-cloud-color-theme: mat.define-light-theme((
  color: (
    primary: $my-app-primary,
    accent: $my-app-accent,
    warn: $my-app-warn
  )
));

$custom-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Noto Sans JP"',
  $subtitle-1: mat.define-typography-level(
    $font-size: 14px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $subtitle-2: mat.define-typography-level(
    $font-size: 14px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $body-1: mat.define-typography-level(
    $font-size: 14px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $body-2: mat.define-typography-level(
    $font-size: 14px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $button: mat.define-typography-level(
    $font-size: 14px,
    $letter-spacing: normal
  )
);
$diva-cloud-thypography-theme: mat.define-light-theme((
  typography: $custom-typography
));

// Add ".theme-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-diva-cloud-light {
    @include mat.all-component-typographies($diva-cloud-thypography-theme);

    // Apply the theme to the Conflow Core
    @include conflow-core-theme($diva-cloud-color-theme);

    // Apply the theme to the user components
    @include components-theme($diva-cloud-color-theme);
}