.channel-list-link mat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

mat-drawer.sidenav-users {
    width: 398px;
    max-width: 90%;
    overflow: hidden;
    ul {
        list-style-type: none;
        margin: 0;
        li {
            padding: 0;
            a {
                padding: 9px 24px;
                position: relative;
                display: block;
                box-sizing: border-box;
                height: 40px;
                text-decoration: none;
                .mat-badge-content {
                    right: 24px !important;
                    top: 9px !important;
                    color: white;
                }
                &:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                    cursor: pointer;
                    &:before {
                        display: block;
                        content: "";
                        width: 500px;
                        height: 40px;
                        position: absolute;
                        background: rgba(0, 0, 0, 0.04);
                        top: 0;
                        right: 100%;
                    }
                }
            }
            app-channel-list-item>a.active {
                color: white;
                background-color: #039be5;
                &:before {
                    display: block;
                    content: "";
                    width: 500px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    right: 100%;
                }
                .mat-badge-content {
                    color: #333;
                    background: #ffffff;
                }
            }
            mat-icon {
                float: right;
                font-size: 12px;
            }
        }
        &.top-level {
            padding: 0;
        }
    }
}