@import "src/app/shared/scss/partials/breakpoints";
.pointer {
    cursor: pointer;
}

.mention {
    border-radius: 6px;
    padding: 3px 3px 3px 0px;
    span {
        margin: 0 0px 0px 3px;
    }
}

.dx-icon {
    font-family: DXIcons !important;
}

snack-bar-container {
    max-width: 70vw !important;
}

app-features {
    app-dashboard {
        nb-layout {
            padding-top: 0 !important;
            .scrollable-container {
                max-width: 100% !important;
                .layout {
                    nb-layout-header {
                        top: 0 !important;
                        max-width: 100% !important;
                        .fixed {
                            max-width: 100% !important;
                        }
                    }
                    .layout-container {
                        width: calc(100vw - 8px);
                        nb-sidebar {
                            .main-container {
                                top: 80px !important;
                            }
                            background-color: #2d313d;
                        }
                        .content {
                            .columns {
                                nb-layout-column {
                                    height: calc(100vh - 81px);
                                    border-left: thin solid #cccccc;
                                    @include media-breakpoint('gt-sm') {
                                        padding: 0px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}