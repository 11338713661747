@import "src/app/shared/animations/animations-ghost";

@mixin channel-theme($theme) {

    $background: map-get($theme, background);

    channel-conversation-ghost {

        .channel-messages-ghost {
            .message-row {
                .avatar,  h2, h3, p {
                    @include animate-ghost-line-light;
                }

            }
        }

    }

    app-channel-member-ghost {

        .channel-members-ghost {
            .member-row {
                .username, .checkbox{      
                    @include animate-ghost-line-light;
                }
            }
        }        

    }

    app-channel-list-ghost {

        .channel-list-row {
            .avatar,  h2, h3, p, .collapse, .item {
                @include animate-ghost-line-light;
            }

        }
    }
}