$themes: (
  diva-cloud-light: (
    diva-blue: #379be5,
    diva-light-blue: #b9dcf7,
    diva-green: #00acc1,
    diva-indigo-blue: #5c6bc0,
    diva-orange: #eb672c,
    diva-pink: #e658a1,
    diva-violet: #ab47bc,
    diva-yellow: #fdd835,
    diva-lime-green: #5cc100,
    diva-error-red: #d0021b,
    diva-light-green: #d0f3f7,
    diva-light-indigo-blue: #cce5f4,
    diva-light-orange: #ffe7dd,
    diva-light-pink: #ffe3f1,
    diva-light-violet: #f6d4fc,
    diva-light-yellow: #fff8db,
    diva-light-lime-green: #defbc3,
    diva-medium-gray: #d8d8d8,
    diva-dark-gray: #cccccc,
    diva-navy: #443f5f,
    diva-dark-blue: #205a85,
    diva-black: #141720,
    diva-light-black: #2d313d,
    diva-bg-light-blue: #e2eff9,
    diva-light-gray: #f5f5f5,
    diva-white: #ffffff,
  ),
);


@mixin themify($themes) {
  @each $theme, $map in $themes {
    :host-context(.theme-#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@mixin themifyNoHostContext($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin regular-button {
  white-space: nowrap;
  
  @include themifyNoHostContext($themes) {
    font-weight: 700;
    background-color: themed("diva-blue");
    color: themed("diva-white");
    line-height: 1;
  }
}

@mixin regular-button-disabled {
  @include themifyNoHostContext($themes) {
    font-weight: 700;
    line-height: 1;
    background-color: #d7ebfa !important;
    color: themed("diva-white") !important;
  }
}

@mixin regular-button-outline {
  @include themifyNoHostContext($themes) {
    font-weight: 700;
    line-height: 1;
    border-color: themed("diva-blue");
    color: themed("diva-blue");
  }
}

@mixin regular-button-outline-disabled {
  @include themifyNoHostContext($themes) {
    font-weight: 700;
    line-height: 1;
    border-color: themed("diva-white");
    color: themed("diva-white");
  }
}

@mixin delete-button {
  @include themifyNoHostContext($themes) {
    font-weight: 700;
    line-height: 1;
    background-color: themed("diva-error-red");
    color: themed("diva-white");
  }
}

@mixin cancel-button {
  @include themifyNoHostContext($themes) {
    font-weight: 700;
    line-height: 1;
    background-color: #F7F7F7;
    border-color: themed("diva-navy");
    color: themed("diva-navy");
  }
}

@mixin snakbar-warn {
  @include themifyNoHostContext($themes) {
    margin: 24px;

    .mdc-snackbar__surface {
      background-color: themed("diva-white");
    }

    .mat-mdc-snack-bar-label {
      max-width: 450px;
      padding: 18px 18px;
    }

    .snack-msg {
      font-weight: bold;
      color: themed("diva-navy");
    }

    .snack-icon {
      color: themed("diva-yellow");
    }

    .snack-close {
      color: themed("diva-navy");
    }
  }
}

@mixin snakbar-error {
  @include themifyNoHostContext($themes) {
    margin: 24px;

    .mdc-snackbar__surface {
      background-color: themed("diva-error-red");
      padding-right: 0;
    }

    .mat-mdc-snack-bar-label {
      max-width: 450px;
      padding: 18px 18px;
    }

    .snack-msg {
      color: themed("diva-white");
    }

    .snack-icon {
      color: themed("diva-white");
    }

    .snack-close {
      color: themed("diva-white");
    }
  }
}

@mixin snakbar-success {
  @include themifyNoHostContext($themes) {
    margin: 24px;
    
    .mdc-snackbar__surface {
      background-color: themed("diva-white");
      padding-right: 0;
    }

    .mat-mdc-snack-bar-label {
      max-width: 450px;
      padding: 18px 18px;
    }

    .snack-msg {
      color: themed("diva-navy");
    }

    .snack-icon {
      color: themed("diva-lime-green");
    }

    .snack-close {
      color: themed("diva-navy");
    }
  }
}

@mixin checkbox {
  @include themifyNoHostContext($themes) {
    .mdc-form-field {
      label {
        font-size: 14px;
      }

      .mdc-checkbox {
        padding: 0;
        margin-right: 10px;

        input {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .mdc-checkbox__background {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          height: 16px;
          width: 16px;
          border-radius: 1px;
        }

        &:hover .mdc-checkbox__ripple,
        .mdc-checkbox__ripple {
          background: #337ab7;
          height: 40px;
          width: 40px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

@mixin mat-icon-overflow {
    @include themifyNoHostContext($themes) {
      overflow: visible;
    } 
}

@mixin switch-toggle {
  @include themify($themes) {
    --mdc-switch-selected-focus-state-layer-color: #379be5;
    --mdc-switch-selected-focus-handle-color: #379be5;
    --mdc-switch-selected-hover-state-layer-color: #379be5;
    --mdc-switch-selected-pressed-state-layer-color: #379be5;
    --mdc-switch-selected-hover-handle-color: #379be5;
    --mdc-switch-selected-pressed-handle-color: #379be5;
    --mdc-switch-selected-handle-color: #379be5;

    --mdc-switch-selected-focus-track-color: #039be58a;
    --mdc-switch-selected-hover-track-color: #039be58a;
    --mdc-switch-selected-pressed-track-color: #039be58a;
    --mdc-switch-selected-track-color: #039be58a;
  }

  @include themifyNoHostContext($themes) {
    --mdc-switch-selected-focus-state-layer-color: #379be5;
    --mdc-switch-selected-focus-handle-color: #379be5;
    --mdc-switch-selected-hover-state-layer-color: #379be5;
    --mdc-switch-selected-pressed-state-layer-color: #379be5;
    --mdc-switch-selected-hover-handle-color: #379be5;
    --mdc-switch-selected-pressed-handle-color: #379be5;
    --mdc-switch-selected-handle-color: #379be5;
    
    --mdc-switch-selected-focus-track-color: #039be58a;
    --mdc-switch-selected-hover-track-color: #039be58a;
    --mdc-switch-selected-pressed-track-color: #039be58a;
    --mdc-switch-selected-track-color: #039be58a;
  }
}

@mixin progress-spinner {
  @include themify($themes) {
    circle {
      stroke: #379be5;
    }
  }

  @include themifyNoHostContext($themes) {
    circle {
      stroke: #379be5;
    }
  }
}

@mixin progress-bar {
  @include themify($themes) {
      border-color: #379be5;
    
  }

  @include themifyNoHostContext($themes) {
      border-color: #379be5;
  }
}