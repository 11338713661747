@import "diva-cloud-theme";

// plugins
@import "./plugins/shared/scss/styles";

* {
  font-family: "Open Sans", "Noto Sans JP";
}
html, body{
  height: 100%;
}
body {
  margin: 0;    
}

.snakbar-error {
  @include snakbar-error;
}

.snakbar-warn {
  @include snakbar-warn;
}

.snakbar-success {
  @include snakbar-success;
}

.mat-mdc-dialog-title {
  font-family: "Open Sans" !important;
}
  
mat-drawer-opened {
  + .mat-drawer-content {
    margin-left: 290px;
  }
}

.mat-mdc-tab {
  .mdc-tab__text-label {
    color: #000 !important;
  }

  .mdc-tab-indicator__content--underline {
    border-color: #000 !important;
  }

  // hover
  .mdc-tab__ripple::before {
    background-color: #000 !important;
  }

  // clicked
  .mat-ripple-element {
    background-color: #000 !important;
  }
}

mat-checkbox.mat-mdc-checkbox.mat-accent {
  @include checkbox;
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #0000008a;
  --mdc-checkbox-selected-hover-icon-color: #039be5;
  --mdc-checkbox-selected-icon-color: #039be5;
  --mdc-checkbox-selected-pressed-icon-color: #039be5;
  --mdc-checkbox-unselected-icon-color: #0000008a;
  --mdc-checkbox-unselected-pressed-icon-color: #039be5;
  --mdc-checkbox-unselected-focus-icon-color: #0000008a;
  --mdc-checkbox-unselected-hover-icon-color: #0000008a;
  
  .mdc-checkbox {
    &:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      background-color: #039be5;
    }
  }
}

.mat-mdc-slide-toggle.mat-accent,
.mat-mdc-slide-toggle.mat-checked{
 @include switch-toggle;
}

.mat-mdc-unelevated-button.mat-primary {
  @include regular-button;
}

.mat-mdc-unelevated-button.mat-primary:disabled {
  @include regular-button-disabled;
}

.mat-mdc-outlined-button.mat-secondary {
  @include regular-button-outline;
}

.mat-mdc-outlined-button.completed-btn,
.mat-mdc-outlined-button.cancel-btn {
  @include cancel-button;
}

.mdc-button.mat-warn {
  @include delete-button;
}

// drop down menu style
.cdk-overlay-pane {
  .mdc-menu-surface.mat-mdc-select-panel,
  .mdc-menu-surface.mat-mdc-autocomplete-panel,
  .mat-mdc-menu-content {
    padding: 0;
  }

  &:not(:has(.filter-select)) {
    .mdc-list-item__primary-text {
      color: #443F5F;
    }
  
    .mat-mdc-option:hover:not(.mdc-list-item--disabled):not(.mdc-list-item--selected) {
      background: #337AB7;
  
      .mdc-list-item__primary-text {
          @include themifyNoHostContext($themes) {
            color: themed("diva-white");
          }
      }
    }
  
    .mat-mdc-option:not(.mdc-list-item--disabled).mdc-list-item--selected {
        .mdc-list-item__primary-text {
            color: #443F5F;
        }
    }
  }
}

button.mat-mdc-button>.mat-icon,
button.mat-mdc-outlined-button>.mat-icon,
button.mat-mdc-unelevated-button>.mat-icon {
  font-size: 24px;
  height: 24px;
  width: 24px;
  margin: 0;
}

.mdc-button:has(mat-spinner) .mdc-button__label {
  display: flex;
  align-items: center;
}

.mat-mdc-menu-item {
  min-height: 38px !important;
}

.mdc-linear-progress__bar-inner {
  @include progress-bar;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  @include progress-spinner;
}

// form field style
mat-form-field {
  margin-bottom: 20px;

  &.mat-focused {
    .mat-mdc-text-field-wrapper {
      .mdc-line-ripple::after {
        @include themifyNoHostContext($themes) {
          border-bottom-color: themed("diva-blue");
        }
      }

      mat-select:not([panelclass="spaceSelector"]) {
        .mat-mdc-select-arrow {
          @include themifyNoHostContext($themes) {
            color: themed("diva-light-black");
          }
        }
      }
    }
  }

  &:not(.mat-form-field-appearance-outline) .mat-mdc-text-field-wrapper {
    padding: 0px;
  }

  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;

    .mat-icon {
      @include themifyNoHostContext($themes) {
        color: themed("diva-light-black");
      }
    }

    .mat-mdc-select-arrow {
      @include themifyNoHostContext($themes) {
        color: themed("diva-light-black");
      }
    }

    .mdc-floating-label:not(.mdc-floating-label--float-above) {
      top: 42px;
    }

    &:not(:has(mat-chip-row)) {
      .mat-mdc-input-element:not(textarea) {
        position: absolute;
        bottom: 6px;
        height: 22px;
      }
    }

    .mat-mdc-input-element {
      font-size: 16px !important;

      @include themifyNoHostContext($themes) {
        caret-color: themed("diva-navy");
      }
    }

    textarea {
      min-height: 26px !important;
    }

    .mdc-line-ripple::after {
      border-bottom-color: transparent;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    // when a form has a valid value
    &:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) {
      .mat-mdc-input-element {
        @include themifyNoHostContext($themes) {
          color: themed("diva-navy");
        }
      }

      .mdc-floating-label {
        @include themifyNoHostContext($themes) {
          color: themed("diva-navy");
        }
      }

      &:hover .mdc-line-ripple::before {
        @include themifyNoHostContext($themes) {
          border-bottom-color: themed("diva-light-black");
        }
      }

      .mdc-line-ripple::before {
        @include themifyNoHostContext($themes) {
          border-bottom-color: themed("diva-light-black");
        }
      }
    }

    // when a form has invalid value
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        @include themifyNoHostContext($themes) {
          color: themed("diva-error-red");
        }
      }

      .mdc-line-ripple::after {
        @include themifyNoHostContext($themes) {
          border-bottom-color: themed("diva-error-red");
        }
      }

      .mdc-line-ripple::before {
        @include themifyNoHostContext($themes) {
          border-bottom-color: themed("diva-error-red");
        }
      }
    }
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) {
    .mdc-notched-outline .mdc-notched-outline__trailing,
    .mdc-notched-outline .mdc-notched-outline__leading {
      border-color: #D8D8D8;
    }

    &:hover {
      .mdc-notched-outline .mdc-notched-outline__trailing,
      .mdc-notched-outline .mdc-notched-outline__leading {
        border-color: #CDDFED;
      }
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused {
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
      border-color: #4F8CC1;
      border-width: 1px;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    padding-left: 0;
    padding-top: 2px;

    .mat-mdc-form-field-error {
      @include themifyNoHostContext($themes) {
        color: themed("diva-error-red");
      }
    }
  }

  // To remove unexpected space
  .mat-mdc-form-field-bottom-align::before {
    display: inline;
  }
}