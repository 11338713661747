$base-color-light: rgb(225, 232, 243);
$ghost-color-light: white;
$base-color-lighter:white;
$ghost-color-lighter:  #EDF1F7;
$base-color-dark: rgb(87, 85, 85);
$ghost-color-dark: black;
$animation-duration: 1000ms; 
$gradient-offset: 52 + 16; 







$base-color: rgba(221, 221, 221, 0.863);
$ghost-color: #ecebebf1;
$animation-duration: 1000ms; 
$gradient-offset: 52 + 16; 
 

@mixin background-gradient { 
  background-image: linear-gradient(90deg, $base-color 0px, $ghost-color 40px, $base-color 80px);
  background-size: 80vw;
}

@keyframes ghost-lines { 
  0%   { background-position: -100px;  }
  40%  { background-position: 40vw;    }
  100% { background-position: 60vw;    }
}

@mixin animate-ghost-line {
  animation: ghost-lines $animation-duration infinite linear;
}








@mixin background-gradient-light { 
    background-image: linear-gradient(90deg, $base-color-light 0px, $ghost-color-light 40px, $base-color-light 80px);
    background-size: 80vw;
}

@mixin background-gradient-dark { 
    background-image: linear-gradient(90deg, $base-color-dark 0px, $ghost-color-dark 40px, $base-color-dark 80px);
    background-size: 80vw;
}

@mixin background-gradient-lighter { 
    background-image: linear-gradient(90deg, $base-color-lighter 0px, $ghost-color-lighter 40px, $base-color-lighter 80px);
    background-size: 80vw;
}

@keyframes ghost-lines { 
    0%   { background-position: -100px;  }
    40%  { background-position: 40vw;    }
    100% { background-position: 60vw;    }
}

@mixin animate-ghost-line-light {
    @include background-gradient-light;
    animation: ghost-lines $animation-duration infinite linear;
}

@mixin animate-ghost-line-dark {
    @include background-gradient-dark;
    animation: ghost-lines $animation-duration infinite linear;
}

@mixin animate-ghost-line-lighter {
    @include background-gradient-lighter;
    animation: ghost-lines $animation-duration infinite linear;
}


